import {Component, OnInit} from '@angular/core';
import {AdminService} from '../../services/admin.service';
import {ActivatedRoute, Router} from '@angular/router';
import {District} from '../../models/district/district.model';
import {DistrictService} from "../../services/district.service";
import {DistrictDto} from "../../common/dtos/districtDto";

@Component({
  selector: `admin-districts`,
  templateUrl: 'adminDistricts.component.html',
  styles: []
})
export class AdminDistrictsComponent implements OnInit {
  districts: DistrictDto[];

  getData() {
    this.districtService.getDistricts().subscribe(r => {
      this.districts = r || [];
    });
  }

  ngOnInit(): void {
    this.getData();
  }

  scan(id: string) {
    this.districtService.scanFiles(id).subscribe(r => {

    }, err => {
      console.log(err);
    });
  }

  constructor(private adminService: AdminService, private districtService: DistrictService, private router: Router) {

  }

  onSuccess() {
    this.getData();
  }
}
