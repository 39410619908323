
<div class="d-flex flex-column">
  <form [@loginFail]="loginState"
        (@loginFail.done)="loginState = 'start'"
        #loginForm="ngForm" (ngSubmit)="onSubmit(loginForm)" class="ml-auto mr-auto mt-4 mb-4"  style="max-width: 400px; width:100%">
    <div class="form-group">
      <label for="email">Email</label>
      <input type="email" class="form-control" id="email" name="email" [(ngModel)]="login.username" required #email="ngModel" email>
    </div>
    <div class="form-group">
      <label for="password">Password</label>
      <input type="password" class="form-control" id="password" name="password" [(ngModel)]="login.password" required #pass="ngModel">
    </div>
    <div class="row mb-1" *ngIf="failureMessage.length > 0">
      <div class="col text-center">
        <span class="text-danger">{{failureMessage}}</span>
      </div>
    </div>
    <div class="btn-group btn-block">
      <button type="button" class="btn btn-outline-secondary" (click)="forgotPassword()">Forgot Password</button>
      <button type="submit" class="btn btn-primary" [disabled]="loginForm.untouched || email.invalid || pass.invalid">Login</button>
    </div>
  </form>
  <hr class="col-4 ml-auto mr-auto">
  <div class="ml-auto mr-auto" style="max-width: 400px; width:100%">
    <googleAuth></googleAuth>
  </div>
</div>


