/**
 * Created by ptdave on 4/29/17.
 */

import {Component, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {StudentService} from '../../services/student.service';
import {Observable} from 'rxjs';
import {NgbTypeaheadConfig, NgbTypeaheadSelectItemEvent} from '@ng-bootstrap/ng-bootstrap';
import {UserService} from '../../services/user.service';
import {Student} from '../../models/student/student.model';
import {Router} from '@angular/router';
import { catchError, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import {AuthService} from '../../services/authService';

@Component({
  selector: 'student-search',
  template: `
    <ng-template #stuSearch let-r="result" let-t="term">
      <span>{{r.firstName}} {{r.lastName}}</span>
    </ng-template>
    <div class="form-inline my-2 my-lg-0" *ngIf="loggedIn">
      <input class="form-control"
             aria-multiline="false"
             autocapitalize="off"
             autocomplete="off"
             autocorrect="off"
             [ngbTypeahead]="search"
             [(ngModel)]="model"
             placeholder="Search by lastname"

             [resultTemplate]="stuSearch"
             (selectItem)="pick($event)">
    </div>    `
})
export class StudentSearchComponent implements OnInit {
  searchCtrl: FormControl = new FormControl();
  options: Student[];
  model: any;
  loggedIn = false;

  constructor(private studentService: StudentService,
    config: NgbTypeaheadConfig,
    private authService: AuthService,
    private router: Router) {
    this.options = [];
    this.loggedIn = false;
  }


  search = (text$: Observable<string>) =>
    text$.pipe(debounceTime(300), distinctUntilChanged(), switchMap(term => {
          if (term.trim().length > 2) {
            return this.studentService.search(term.trim()).pipe(catchError((err: any) => {
              return [];
            }));
          }
          return [];
        }
      ))


  pick(event: NgbTypeaheadSelectItemEvent) {
    event.preventDefault();
    this.select(<Student>event.item);
    this.model = '';
  }

  select(student: Student) {
    this.router.navigate(['/student', student.districtId, student.id]);
  }

  ngOnInit() {
    this.authService.isLoggedIn.subscribe(resp => {
      if (resp) {
        // We don't want to say we are if the resetPassword claim is set
        const token = this.authService.parseJwt();
        if (token && !token.resetPassword) {
          this.loggedIn = true;
        }
        return;
      }
      this.loggedIn = resp;
    });
  }
}
