import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {AuthService} from '../services/authService';
import {Observable} from 'rxjs';

@Injectable()
export class ResetPasswordGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (!this.authService.isAuthenticated()) {
      this.router.navigate([
        '/',
        'login'
      ]);
      return false;
    }
    const payload = this.authService.parseJwt();

    if (payload?.resetPassword) {
      return true;
    }

    this.router.navigate([
      '/',
      'home'
    ]);
    return false;
  }
}
