<div class="activity" [hidden]="!isLoading">
  <ngb-progressbar type="success" [value]="100" [striped]="true" height="5px" [animated]="true"></ngb-progressbar>
</div>
<div class="errorContainer">
  <app-notification [toast]="toast" *ngFor="let toast of toastService.Toasts"></app-notification>
  <app-import-dropdown></app-import-dropdown>
</div>
<div class="grid-container">
  <nav class="grid-header navbar navbar-dark bg-dark d-flex flex-row">
    <a [routerLink]="homeRoute" class="cursor-pointer"><span class="navbar-brand">SIDR Online</span></a>
    <ng-container *ngIf="!isLoggedIn">
      <span class="mr-auto"></span>
      <ul class="navbar-nav mr-2">
        <li class="nav-item">
          <a href="https://info.sidronline.com" class="nav-link">Request a Demo</a>
        </li>
      </ul>
    </ng-container>
    <ng-container *ngIf="fullLogin">
      <span class="navbar-text mr-auto"><district-name></district-name></span>
      <student-search class="d-print-none"></student-search>
    </ng-container>
    <login class="d-print-none"></login>
  </nav>
  <div class="grid-content">
    <router-outlet></router-outlet>
    <div class="container d-print-none">
      <div class="row p-2">
        <div class="col-md text-center">
          <a [routerLink]="['/privacy']">Privacy Policy</a>
        </div>
        <div [isSignedIn]="false" class="col-md text-center">
          <a [routerLink]="['/demo']">Request a Demo</a>
        </div>
      </div>
    </div>
  </div>
</div>
