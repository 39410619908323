<div class="navbar bg-primary navbar-inverse d-print-none d-flex flex-row">
  <span class="navbar-brand text-light">District Administration</span>
  <div ngbDropdown>
    <button class="btn btn-primary" id="district_dropdown" [ngClass]="{'active':isActive('district')}" ngbDropdownToggle>Basic</button>
    <div ngbDropdownMenu aria-labelledby="district_dropdown">
      <a class="dropdown-item" [routerLink]="['/district',districtId,'basic','general']" routerLinkActive="active">General</a>
      <a class="dropdown-item" [routerLink]="['/district',districtId,'basic','buildings']" routerLinkActive="active">Buildings</a>
      <a class="dropdown-item" [routerLink]="['/district',districtId,'basic','contact']" routerLinkActive="active">Contact</a>
      <a class="dropdown-item" [routerLink]="['/district',districtId,'basic','domains']" routerLinkActive="active">Domains</a>
      <a class="dropdown-item" [routerLink]="['/district',districtId,'basic','roles']" routerLinkActive="active">Roles</a>
      <a class="dropdown-item" [routerLink]="['/district',districtId,'school-years']" routerLinkActive="active">School Years</a>
    </div>
  </div>
  <a class="btn btn-primary" [routerLink]="['/district',districtId,'imports']" routerLinkActive="active">Imports</a>
  <a class="btn btn-primary" [routerLink]="['/district',districtId,'reports']" routerLinkActive="active">Reports</a>
  <!--<a class="btn btn-primary" [routerLink]="['/district',districtId,'logs']" routerLinkActive="active">Logs</a>-->
  <div ngbDropdown>
    <button class="btn btn-primary" id="modules_dropdown" [ngClass]="{'active':isActive('modules')}" ngbDropdownToggle>Modules</button>
    <div ngbDropdownMenu aria-labelledby="modules_dropdown">
      <a class="dropdown-item" [routerLink]="['/district',districtId,'module','basic','academic_area']"
         routerLinkActive="active">Academic Areas</a>
      <a class="dropdown-item" [routerLink]="['/district',districtId,'module','basic','achievements']"
         routerLinkActive="active">Achievements</a>
      <a class="dropdown-item" [routerLink]="['/district',districtId,'module','assessments']"
         routerLinkActive="active">Assessments</a>
      <a class="dropdown-item" [routerLink]="['/district',districtId,'module','basic','grades']"
         routerLinkActive="active">Grades</a>
      <a [routerLink]="['/district',districtId,'module','basic','intervention_type']" class="dropdown-item"
         routerLinkActive="active">Intervention Types</a>
      <a [routerLink]="['/district',districtId,'module','basic','intervention_tiers']" class="dropdown-item"
         routerLinkActive="active">Intervention Tiers</a>
      <a class="dropdown-item" [routerLink]="['/district',districtId,'module','irip']"
         routerLinkActive="active">IRIP</a>
      <a class="dropdown-item" [routerLink]="['/district',districtId,'module','meetings']" routerLinkActive="active">Meetings</a>
      <a class="dropdown-item" [routerLink]="['/district',districtId,'module','basic','otherfactor']"
         routerLinkActive="active">Other Factors</a>

    </div>
  </div>
  <a class="btn btn-primary mr-auto" [routerLink]="['/district',districtId,'staff']" routerLinkActive="active">Staff</a>
</div>
<div class="m-4" *ngIf="ready" >
  <router-outlet></router-outlet>
</div>
