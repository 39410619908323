
<div class="d-flex flex-column">
  <div class="ml-auto mr-auto mt-4 mb-4 col-sm-12 col-md-6 col-lg-4">
    <form #f="ngForm" (ngSubmit)="changePassword(f)">
      <div class="form-group">
        <label for="currentPassword">Current Password</label>
        <input #cPassword="ngModel" type="password" class="form-control" id="currentPassword" name="currentPassword" [(ngModel)]="currentPassword" required>
        <ng-container *ngIf="cPassword.dirty && cPassword.errors">
          <span *ngIf="cPassword.errors['required']" class="text-danger">
            Please enter your password
          </span>
        </ng-container>
      </div>
      <div class="form-group">
        <label for="password">New Password</label>
        <input #newPassword="ngModel" type="password" class="form-control" id="password" name="password" [(ngModel)]="password" required minlength="8">
        <ng-container *ngIf="newPassword.dirty && newPassword.errors">
          <span *ngIf="newPassword.errors['required']" class="text-danger">
            A valid password is required
          </span>
          <span *ngIf="newPassword.errors['minlength']" class="text-danger">
            Password must be at least 8 characters
          </span>
        </ng-container>
      </div>
      <div class="form-group">
        <label for="confirmPassword">Confirm Password</label>
        <input #confirmPassword="ngModel" type="password" class="form-control" id="confirmPassword" name="confirmPassword" [(ngModel)]="matchPassword" required>
        <span *ngIf="f.form.touched" class="text-danger"></span>
      </div>
      <button type="submit" class="btn btn-primary btn-block" [disabled]="!newPassword?.valid && !confirmPassword.valid">Change Password</button>
    </form>
  </div>
</div>


