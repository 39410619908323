import {Component} from "@angular/core";
import {NgForm} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {Observation} from '../../models/student/observation.model';
import {Router} from '@angular/router';
import {AuthService} from '../../services/authService';
import {animate, keyframes, state, style, transition, trigger} from '@angular/animations';

class Login {
  username: string;
  password: string;
}

@Component({
  selector: `LoginPage`,
  templateUrl: 'loginPage.component.html',
  animations: [
    trigger('loginFail', [
      state('start', style({
        transform: 'scale(1)'
      })),
      state('end', style({
        transform: 'scale(1)'
      })),
      transition('start => end', animate('1000ms ease-in',
        keyframes([
          style({transform: 'translate3d(-1px, 0, 0)', offset: 0.1}),
          style({transform: 'translate3d(2px, 0, 0)', offset: 0.2}),
          style({transform: 'translate3d(-4px, 0, 0)', offset: 0.3}),
          style({transform: 'translate3d(4px, 0, 0)', offset: 0.4}),
          style({transform: 'translate3d(-4px, 0, 0)', offset: 0.5}),
          style({transform: 'translate3d(4px, 0, 0)', offset: 0.6}),
          style({transform: 'translate3d(-4px, 0, 0)', offset: 0.7}),
          style({transform: 'translate3d(2px, 0, 0)', offset: 0.8}),
          style({transform: 'translate3d(-1px, 0, 0)', offset: 0.9}),
        ])))
    ])
  ]
})
export class LoginPageComponent {
  loginState = 'start';
  failureMessage = '';
  public login: Login = new Login();
  onSubmit(form: NgForm) {
    this.failureMessage = '';
    this.http.post<any>(`/api/user/login`, {
      'username': this.login.username,
      'password': this.login.password,
    }).subscribe(p => {
      this.authService.setToken(p.token);
      this.authService.setRefresh(p.refresh);
      this.authService.isLoggedIn.next(true);

      const token = this.authService.parseJwt();
      if (token.resetPassword) {
        this.router.navigate([
          '/',
          'changepassword',
        ]);
        return;
      } else {
        this.router.navigate(['/']);
      }
    }, err => {
      this.loginState = 'end';
      this.failureMessage = 'Invalid email or password';
    });
  }

  forgotPassword() {
    this.router.navigate(['forgotPassword']);
  }
  constructor(private authService: AuthService, private http: HttpClient, private router: Router) {
  }
}
