import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ComponentsModule} from './components/components.module';
import {RequireFeatureDirective} from './directives/require-feature.directive';
import {DistrictModule} from './district/district.module';
import {RadioButtonComponent, RadioButtonsComponent} from './helpers/radio-buttons/radio-buttons.component';
import {HomeDefaultComponent} from './home-modules/home-default/home-default.component';
import {AuthService} from './services/authService';
import {AdminService} from './services/admin.service';
import {DistrictService} from './services/district.service';
import {FeatureService} from './services/feature-service.service';
import {NewsService} from './services/news.service';
import {OptionsService} from './services/options.service';
import {StaffService} from './services/staff.service';
import {StudentService} from './services/student.service';
import {TitleService} from './services/title.service';
import {UserService} from './services/user.service';
import {StudentModule} from './student/student.module';
import {HomeViewComponent} from './views/home-view/home-view.component';
import {NoPathViewComponent} from './views/no-path-view/no-path-view.component';
import {TestViewComponent} from './views/test-view/test-view.component';
import {TokenViewComponent} from './views/token-view/token-view.component';
import {AuthInterceptor} from './services/http/auth.interceptor';
import {SIDRCommonModule} from './common/sidrCommon.module';
import {UnauthenticatedModule} from './unauthenticated/unauthenticated.module';
import {CanDeactivateService, CanDeactivateServiceModal} from './services/canDeactivate.service';
import {ActivityInterceptor} from './services/http/activity.interceptor';
import {ActivityService} from './services/activity.service';
import {StaffModule} from './staff/staff.module';
import {ReportService} from './services/report.service';
import {AdminModule} from './admin/adminModule';
import {TypesService} from './services/types.service';
import {LoggingService} from './services/logging';
import {ResetPasswordGuard} from './guards/resetPassword.guard';
import {LoggedInGuard} from './guards/loggedIn.guard';
import {LoggedOutGuard} from './guards/loggedOut.guard';

@NgModule({
  declarations: [
    AppComponent,
    HomeDefaultComponent,
    RadioButtonComponent,
    RadioButtonsComponent,
    RequireFeatureDirective,
    HomeViewComponent,
    TokenViewComponent,
    TestViewComponent,
    NoPathViewComponent,
    CanDeactivateServiceModal
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AdminModule,
    UnauthenticatedModule,
    SIDRCommonModule,
    ComponentsModule,
    DistrictModule,
    StaffModule,
    StudentModule,
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: ActivityInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    //{ provide: ErrorHandler, useClass: SIDRErrorHandler },
    AuthInterceptor,
    ActivityService,
    AdminService,
    CanDeactivateService,
    DistrictService,
    NewsService,
    OptionsService,
    StaffService,
    StudentService,
    UserService,
    ResetPasswordGuard,
    LoggedInGuard,
    LoggedOutGuard,
    ReportService,
    FeatureService,
    TitleService,
    AuthService,
    TypesService,
    LoggingService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
