import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {AuthService} from '../../services/authService';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'app-changePassword',
  templateUrl: './changePassword.component.html',
})
export class ChangePasswordComponent {

  currentPassword: string;
  password: string;
  matchPassword: string;

  changePassword(f: NgForm) {
    this.httpClient.post('/api/user/changePassword', {
      currentPassword: this.currentPassword,
      newPassword: this.password
    }).subscribe(res => {
      this.authService.isLoggedIn.next(false);
      this.authService.clear();
      this.router.navigate(['/login']);
    }, err => {
      // Display some error

    });
  }

  constructor(private httpClient: HttpClient, private router: Router, private authService: AuthService) {
  }
}
