import {Component, EventEmitter, Output} from '@angular/core';
import {DistrictDto} from '../../common/dtos/districtDto';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NgForm} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {Level, ToastService} from '../../services/toast.service';

@Component({
  selector: 'app-create-district',
  templateUrl: './createDistrict.component.html',
})
export class CreateDistrictComponent {
  @Output() success = new EventEmitter<any>();

  constructor(private modalService: NgbModal, private http: HttpClient, private toastService: ToastService) {
    this.district = {
      name: '',
      contact: {
        name: '',
        email: '',
        number: '',
      },
      districtImageUrl: '',
      domains: [''],
      id: null,
      slogan: ''
    };
  }

  district: DistrictDto;

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
      (result) => {
        if (result === 'success') {
          this.success.emit(true);
        }
      },
      (reason) => {
      },
    );
  }

  submit(addDistrictForm: NgForm, activeModal: NgbActiveModal) {
    console.log(addDistrictForm);
    if (addDistrictForm.untouched || addDistrictForm.invalid) {
      console.log(addDistrictForm.errors);
      addDistrictForm.form.markAsTouched();
      return;
    }

    this.http.post('/api/district', this.district).subscribe(resp => {
      this.toastService.AddNotification(Level.SUCCESS, 'District', 'Add new district');
      activeModal.close('success');
    }, (reason) => {
      this.toastService.AddNotification(Level.ERROR, 'District', 'Couldn\'t add new district');
    });
  }
}
