<ng-template #modal let-modal>
  <form #addDistrictForm="ngForm" (ngSubmit)="submit(addDistrictForm, modal)">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Add District</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

      <div class="form-group">
        <label for="name">District Name*</label>
        <input #nameCtrl="ngModel" class="form-control" type="text" required name="name" id="name" [(ngModel)]="district.name" minlength="3" placeholder="Name of the School District">
        <ng-container *ngIf="nameCtrl.touched && nameCtrl.errors">
          <span *ngIf="nameCtrl.errors['required']" class="text-danger">You must provide a name for the school district</span>
          <span *ngIf="nameCtrl.errors['minlength']" class="text-danger">Must be at least 3 characters</span>
        </ng-container>
      </div>
      <div class="form-group">
        <label for="slogan">Slogan</label>
        <input class="form-control" type="text" name="slogan" id="slogan" [(ngModel)]="district.slogan" placeholder="Slogan, if any">
      </div>
      <div class="form-group">
        <label for="contact-name">Contact Name*</label>
        <input #contactNameCtrl="ngModel" class="form-control" type="text" name="contact-name" id="contact-name" required minlength="3" [(ngModel)]="district.contact.name" placeholder="Who the district contact is">
        <ng-container *ngIf="contactNameCtrl.touched && contactNameCtrl.errors">
          <span *ngIf="contactNameCtrl.errors['required']" class="text-danger">You must provide a contact name for the school district</span>
          <span *ngIf="contactNameCtrl.errors['minlength']" class="text-danger">Must be at least 3 characters</span>
        </ng-container>
      </div>
      <div class="form-group">
        <label for="contact-email">Contact E-Mail</label>
        <input #contactEmailCtrl="ngModel" class="form-control" type="email" required email id="contact-email" name="contact-email" [(ngModel)]="district.contact.email" placeholder="student-services@someschool.org">
        <ng-container *ngIf="contactEmailCtrl.touched && contactEmailCtrl.errors">
          <span *ngIf="contactEmailCtrl.errors['required']" class="text-danger">You must provide a contact email for the school district</span>
          <span *ngIf="contactEmailCtrl.errors['minlength']" class="text-danger">Must be at least 3 characters</span>
          <span *ngIf="contactEmailCtrl.errors['email']" class="text-danger">Invalid email</span>
        </ng-container>
      </div>
      <div class="form-group">
        <label for="contact-number">Contact Number</label>
        <input #contactNumberCtrl="ngModel" class="form-control" type="tel" required id="contact-number" name="contact-number" [(ngModel)]="district.contact.number" placeholder="810-555-5555" minlength="10">
        <ng-container *ngIf="contactNumberCtrl.touched && contactNumberCtrl.errors">
          <span *ngIf="contactNumberCtrl.errors['required']" class="text-danger">You must provide a contact phone number for the school district</span>
          <span *ngIf="contactNumberCtrl.errors['minlength']" class="text-danger">Must be at least 10 characters</span>
        </ng-container>
      </div>
      <div class="form-group">
        <label for="district-domain">District Domain</label>
        <input #districtDomainCtrl="ngModel" class="form-control" type="text" required id="district-domain" name="district-domain" [(ngModel)]="district.domains[0]" minlength="6" placeholder="schoolname.com">
        <ng-container *ngIf="districtDomainCtrl.touched && districtDomainCtrl.errors">
          <span *ngIf="districtDomainCtrl.errors['required']" class="text-danger">You must provide a domain name for the school district</span>
          <span *ngIf="districtDomainCtrl.errors['minlength']" class="text-danger">Must be at least 6 characters</span>
        </ng-container>
      </div>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-success" [disabled]="addDistrictForm.untouched && addDistrictForm.invalid">Save</button>
    <button type="button" class="btn btn-warning" (click)="modal.dismiss('cancel')">Cancel</button>
  </div>

  </form>
</ng-template>
<div class="btn btn-outline-secondary" (click)="open(modal)">Add New District</div>
