<div class="container-fluid d-flex">
  <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills" orientation="vertical"
      (activeIdChange)="this.getData($event)" [destroyOnHide]="false">
    <ng-container *ngFor="let building of buildings">
      <li [ngbNavItem]="building.id">
        <a ngbNavLink>{{building.name}}</a>
        <ng-template ngbNavContent>
          <div class="container-fluid">
            <div class="form-group row">
              <div class="col">
                <label for="suspended">
                  <span class="align-middle">Is Active?</span>
                </label>
              </div>
              <div class="col">
                <select class="custom-select pull-left" id="suspended" [(ngModel)]="suspended">
                  <option [ngValue]="true">Suspended</option>
                  <option [ngValue]="false">Active</option>
                </select>
              </div>

            </div>

            <ng-container *ngIf="staff.length === 0">
              <div class="row justify-content-center">
                <p>There is not currently any staff attached to this building</p>
              </div>
            </ng-container>
          </div>
          <ng-container *ngIf="staff.length > 0">
            <div class="row">
              <div class="col-3 border-left border-bottom font-weight-bold">Last Name</div>
              <div class="col-3 border-left border-bottom font-weight-bold">First Name</div>
              <div class="col-2 border-left border-bottom font-weight-bold">Last Login</div>
              <div class="col-2 border-left border-bottom font-weight-bold">E-Mail</div>
              <div class="col-2 border-left border-bottom font-weight-bold"></div>
            </div>
            <ng-container *ngFor="let s of staff">
              <div class="row" *ngIf="s.disabled === suspended">
                <div class="col-3 border-left border-bottom text-truncate">
                  <span class="align-middle">
                    {{s.lastName}}
                  </span>
                </div>
                <div class="col-3 border-left border-bottom text-truncate">
                  <span class="align-middle">
                    {{s.firstName}}
                  </span>
                </div>
                <div class="col-2 border-left border-bottom text-truncate">
                  <span class="align-middle">
                    <ng-container *ngIf="s.lastLogin === null">
                      N/A
                    </ng-container>
                    <ng-container *ngIf="s.lastLogin !== null">
                      {{s.lastLogin | date : 'short'}}
                    </ng-container>
                  </span>
                </div>
                <div class="col-2 border-left border-bottom text-truncate">
                  <span class="align-middle">
                    {{s.email}}
                  </span>
                </div>
                <div class="col-2 border-left border-bottom">
                  <a class="btn btn btn-outline-primary btn-sm pull-right" [routerLink]="['/staff/' + s.id]">
                    <i class="fas fa-chevron-right"></i> Details
                  </a>
                </div>
              </div>
            </ng-container>
          </ng-container>


        </ng-template>
      </li>
    </ng-container>
  </ul>
  <div [ngbNavOutlet]="nav" class="col ml-4 mt-2"></div>
</div>

