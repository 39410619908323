import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {UserService} from 'src/app/services/user.service';
import {AdminService} from 'src/app/services/admin.service';
import {AdminDistrictsComponent} from 'src/app/admin/districts/adminDistricts.component';
import {AdminDistrictComponent} from 'src/app/admin/districts/adminDistrict.component';
import {StudentCanDeactivate} from 'src/app/components/helpers/StudentCanDeactivate';
import {HomeBuildingAdminComponent} from 'src/app/components/home/home-building-admin/home-building-admin.component';
import {HomeDistrictAdminComponent} from 'src/app/components/home/home-district-admin/home-district-admin.component';
import {HomeViewComponent} from './views/home-view/home-view.component';
import {DistrictViewComponent} from './district/district.view/district-view.component';
import {AdminViewComponent} from './admin/view/admin-view.component';
import {TokenViewComponent} from './views/token-view/token-view.component';
import {StaffPageComponent} from './staff/staffPage/staffPage.component';
import {HomeDefaultComponent} from './home-modules/home-default/home-default.component';
import {DistrictGeneralComponent} from './district/district.general/general.component';
import {DistrictImportsComponent} from './district/district.imports/imports.component';
import {DistrictBuildingsComponent} from './district/district.buildings/buildings.component';
import {DistrictReportsComponent} from './district/district.reports/reports.component';
import {DistrictStaffComponent} from './district/district.staff/staff.component';
import {IntroPageComponent} from './unauthenticated/introPage/introPage.component';
import {PrivacyPageComponent} from './unauthenticated/privacyPage/privacyPage.component';
import {LoginPageComponent} from './unauthenticated/loginPage/loginPage.component';
import {StudentPageComponent} from './student/studentPage/studentPage.component';
import {CanDeactivateService} from './services/canDeactivate.service';
import {DistrictModulesComponent} from './district/district.modules/districtModules.component';
import {ModuleIRIPComponent} from './district/district.modules/irip/moduleIRIP.component';
import {ModuleMeetingsComponent} from './district/district.modules/meetings/moduleMeetings.component';
import {ModuleBasicTypesComponent} from './district/district.modules/basicTypes/moduleBasicTypes.component';
import {ImportDescComponent} from './district/district.imports/importDesc.component';
import {DemoComponent} from './unauthenticated/demoPage/demo.component';
import {TypesService} from './services/types.service';
import {DistrictContactComponent} from './district/district.contact/contact.component';
import {DistrictDomainsComponent} from './district/district.domains/domains.component';
import {DistrictSchoolYearComponent} from './district/district.modules/schoolYear/schoolYear.component';
import {AdminFeaturesComponent} from './admin/features/adminFeatures.component';
import {AdminFeatureComponent} from './admin/features/adminFeature.component';
import {AssessmentsComponent} from './district/assessments/assessments.component';
import {ForgotPasswordComponent} from './unauthenticated/forgotpassword/forgotpassword.component';
import {RoleListComponent} from './district/district.roles/roleList.component';
import {ChangePasswordComponent} from './unauthenticated/changePassword/changePassword.component';
import {LoggedInGuard} from './guards/loggedIn.guard';
import {ResetPasswordGuard} from './guards/resetPassword.guard';
import {LoggedOutGuard} from './guards/loggedOut.guard';

const routes: Routes = [
  {
    path: 'login',
    component: LoginPageComponent,
    canActivate: [LoggedOutGuard]
  },
  {path: 'privacy', component: PrivacyPageComponent},
  {
    path: 'demo',
    component: DemoComponent,
    canActivate: [LoggedOutGuard]
  },
  {
    path: 'forgotPassword',
    component: ForgotPasswordComponent,
    canActivate: [LoggedOutGuard]
  },
  {
    path: 'admin',
    component: AdminViewComponent,
    canActivate: [LoggedInGuard, AdminService],
    children: [
      {
        path: 'districts',
        component: AdminDistrictsComponent
      },
      {
        path: 'district/:district_id',
        component: AdminDistrictComponent
      },
      {
        path: 'features',
        component: AdminFeaturesComponent
      },
      {
        path: 'features/:feature_id',
        component: AdminFeatureComponent
      }
    ]
  },
  {
    path: 'student/:district_id/:student_id',
    component: StudentPageComponent,
    canActivate: [LoggedInGuard],
    canDeactivate: [StudentCanDeactivate]
  },
  {
    path: 'district/:districtId',
    component: DistrictViewComponent,
    canActivate: [LoggedInGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'basic/general'
      },
      {
        path: 'basic',
        children: [
          {
            path: 'general',
            component: DistrictGeneralComponent,
            canDeactivate: [CanDeactivateService]
          },
          {
            path: 'contact',
            component: DistrictContactComponent,
            canDeactivate: [CanDeactivateService]
          },
          {
            path: 'domains',
            component: DistrictDomainsComponent,
            canDeactivate: [CanDeactivateService]
          },
          {
            path: 'buildings',
            component: DistrictBuildingsComponent
          },
          {
            path: 'roles',
            component: RoleListComponent,
          }
        ]
      },
      {
        path: 'imports',
        component: DistrictImportsComponent
      },
      {
        path: 'importFiles',
        component: ImportDescComponent,
      },
      {
        path: 'reports',
        component: DistrictReportsComponent
      },
      {
        path: 'staff',
        component: DistrictStaffComponent
      },
      {
        path: 'school-years',
        component: DistrictSchoolYearComponent
      },
      {
        path: 'module',
        component: DistrictModulesComponent,
        children: [
          {
            path: 'assessments',
            component: AssessmentsComponent
          },
          {
            path: 'irip',
            component: ModuleIRIPComponent
          },
          {
            path: 'meetings',
            component: ModuleMeetingsComponent
          },
          {
            path: 'basic/achievements',
            component: ModuleBasicTypesComponent,
            data: {
              title: 'Achievement Types',
              basicType: TypesService.Achievements,
              description: 'Below are a list of achievement types for your district. To add a new type, use the text box below and click the plus.\n  To delete a type, select the trash can next to the appropriate type.',
              placeholderText: 'Enter the name of a achievement type here',
              requiredText: 'A value is required to be entered to add a new achievement type',
              minLengthText: 'Value must be at least 3 characters long',
            },
            canDeactivate: [CanDeactivateService]
          },
          {
            path: 'basic/intervention_type',
            component: ModuleBasicTypesComponent,
            data: {
              title: 'Intervention Type',
              basicType: TypesService.InterventionType,
              description: 'Below are a list of intervention types for your district. To add a new type, use the text box below and click the plus.\n  To delete a type, select the trash can next to the appropriate type.',
              placeholderText: 'Enter the name of a intervention type here',
              requiredText: 'A value is required to be entered to add a new intervention type',
              minLengthText: 'Value must be at least 3 characters long',
            },
            canDeactivate: [CanDeactivateService]
          },
          {
            path: 'basic/intervention_tiers',
            component: ModuleBasicTypesComponent,
            data: {
              title: 'Intervention Tiers',
              basicType: TypesService.InterventionTier,
              description: 'Below are a list of intervention tiers for your district. To add a new tier, use the text box below and click the plus.\n  To delete a type, select the trash can next to the appropriate tier.',
              placeholderText: 'Enter the name of a intervention tier here',
              requiredText: 'A value is required to be entered to add a new intervention tier',
              minLengthText: 'Value must be at least 3 characters long',
            },
            canDeactivate: [CanDeactivateService]
          },
          {
            path: 'basic/otherfactor',
            component: ModuleBasicTypesComponent,
            data: {
              title: 'Other Factors',
              basicType: TypesService.OtherFactor,
              description: 'Below are a list of other factors for your district. To add a new factor, use the text box below and click the plus.\n  To delete a factor, select the trash can next to the appropriate type.',
              placeholderText: 'Enter the name of a other factor here',
              requiredText: 'A value is required to be entered to add a new other factor',
              minLengthText: 'Value must be at least 3 characters long',
            },
            canDeactivate: [CanDeactivateService]
          },
          {
            path: 'basic/academic_area',
            component: ModuleBasicTypesComponent,
            data: {
              title: 'Academic Areas',
              basicType: TypesService.AcademicArea,
              description: 'Below are a list of academic areas for your district. To add a new factor, use the text box below and click the plus.\n  To delete a factor, select the trash can next to the appropriate type.',
              placeholderText: 'Enter the name of an academic area here',
              requiredText: 'A value is required to be entered to add a new area',
              minLengthText: 'Value must be at least 3 characters long',
            },
            canDeactivate: [CanDeactivateService]
          },
          {
            path: 'basic/school_years',
            component: ModuleBasicTypesComponent,
            data: {
              title: 'School Years',
              basicType: TypesService.SchoolYear,
              description: 'Below are a list of School Years available for staff members to use to fill in.',
              placeholderText: 'Enter a school year here, i.e. 2021-2022',
              requiredText: 'A value is required to be entered to add a school year',
              minLengthText: 'Value must be at least 3 characters long',
            },
            canDeactivate: [CanDeactivateService]
          },
          {
            path: 'basic/grades',
            component: ModuleBasicTypesComponent,
            data: {
              title: 'Grades',
              basicType: TypesService.Grades,
              description: 'Below are a list of grades available for staff members to use to fill in.',
              placeholderText: 'Enter a grade level here, i.e. 1, 2, 3',
              requiredText: 'A value is required to be entered to add a grade',
              minLengthText: 'Value must be at least 1 characters long',
              minLength: 1,
              defaults: ['PK', 'K', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']
            },
            canDeactivate: [CanDeactivateService]
          }
        ]
      }
    ]
  },
  {
    path: 'staff/:staff_id',
    component: StaffPageComponent,
    canActivate: [LoggedInGuard],
    canDeactivate: [CanDeactivateService]
  },
  {
    path: 'home',
    component: HomeViewComponent,
    canActivate: [LoggedInGuard],
    children: [
      {
        path: 'building',
        component: HomeBuildingAdminComponent
      },
      {
        path: 'district',
        component: HomeDistrictAdminComponent
      },
      {
        path: '',
        component: HomeDefaultComponent
      }
    ]
  },
  { path: 'token', component: TokenViewComponent },
  {
    path: 'changepassword',
    component: ChangePasswordComponent,
    canActivate: [ResetPasswordGuard],
  },
  { path: '', component: IntroPageComponent, canActivate: [LoggedOutGuard] },
  {path: '**', component: IntroPageComponent}
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
