<div class="mt-1 container d-flex flex-column">
  <div class="d-flex flex-row">
    <h3>School Districts</h3>
    <span class="mr-auto"></span>
    <app-create-district (success)="onSuccess()"></app-create-district>
  </div>
  <ul class="list-group">
    <li *ngFor="let district of districts" class="list-group-item d-flex flex-row">
      <span class="mr-auto">{{district?.name}}</span>
      <a [routerLink]="['/district',district.id]" class="btn btn-outline-info"><i class="fas fa-search"></i></a>
      <button class="btn btn-info" (click)="scan(district.id)">File Scan</button>
    </li>
  </ul>
</div>
