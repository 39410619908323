import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import { ToastService } from './services/toast.service';
import {AuthService} from './services/authService';
import {ActivityService} from './services/activity.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  isLoggedIn: boolean;
  homeRoute: string[];
  isLoading: boolean;
  fullLogin: boolean;

  ngOnInit(): void {
    this.authService.isLoggedIn.subscribe(x => {
      this.homeRoute = x ? ['/', 'home'] : ['/'];
      this.isLoggedIn = x;
      this.fullLogin = false;

      if (this.isLoggedIn) {
        const jwt = this.authService.parseJwt();
        if (jwt && jwt.resetPassword) {
          this.fullLogin = false;
        } else {
          this.fullLogin = true;
        }
      }
    });
  }

  constructor(private router: Router, public toastService: ToastService, private authService: AuthService, private activity: ActivityService) {
    this.activity.isLoading?.subscribe(x => {
      this.isLoading = x;
    });
  }
}
