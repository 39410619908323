import {Component, OnInit} from '@angular/core';
import {RoleDto} from '../../common/dtos/roleDto';
import {NgForm} from '@angular/forms';
import {NgbModal, NgbNav} from '@ng-bootstrap/ng-bootstrap';
import {DistrictService} from '../../services/district.service';
import {ActivatedRoute} from '@angular/router';
import {TitleService} from '../../services/title.service';
import {Level, ToastService} from '../../services/toast.service';
import {ConfirmModal} from '../../components/modals/confirm/confirm.modal';

@Component({
  selector: 'app-role-list',
  templateUrl: './roleList.component.html',
})
export class RoleListComponent implements OnInit {
  roles: RoleDto[];
  active: any;
  districtId: string;

  sizing = 'col-sm-12 col-md-4 col-lg-3';

  constructor(
    private districtService: DistrictService,
    private activeRoute: ActivatedRoute,
    private titleService: TitleService,
    private toastService: ToastService,
    private modal: NgbModal) {
    this.roles = [];
    this.titleService.setTitle('Role Management');
  }

  getData() {
    this.districtService.getRoles(this.districtId).subscribe(roles => {
      this.roles = roles || [];
    });
  }

  ngOnInit(): void {
    this.activeRoute.parent.parent.params.subscribe(p => {
      if (p['districtId']) {
        this.districtId = p['districtId'];
        this.getData();
      }
    });
  }

  submit(roleForm: NgForm, role: RoleDto) {
    if (role.id && role.id.length > 0 && role.id !== 'new') {
      this.districtService.patchRole(this.districtId, role).subscribe(() => {
        this.toastService.AddNotification(Level.SUCCESS, 'Role Saved', 'Role has been saved');
        this.getData();
      }, err => {
        this.toastService.AddNotification(Level.ERROR, 'Role Error', 'Failed to save role');
      });
    } else {
      if (role.id === 'new') {
        delete role.id;
      }
      this.districtService.postRole(this.districtId, role).subscribe(() => {
        this.toastService.AddNotification(Level.SUCCESS, 'Role Added', 'Role has been added');
        this.getData();
      }, err => {
        this.toastService.AddNotification(Level.ERROR, 'Building Error', 'Failed to add building');
      });
    }
  }

  delete(role: RoleDto) {
    const ref = this.modal.open(ConfirmModal, {
      backdrop: 'static',
      centered: true,
    });
    ref.componentInstance.message = 'You are about to delete a role. This will potentially cause issues on staff, are you sure?';
    ref.result.then(resp => {
      if (resp) {
        this.districtService.deleteRole(this.districtId, role).subscribe(() => {
          this.modal.dismissAll();
          this.getData();
        });

      }
    }, x => {});
  }

  cancel(Form: NgForm) {

  }

  addrole(nav: NgbNav) {
    const role: RoleDto = {
      id: 'new',
      name: 'New Role',
      can_impersonate: false,
      can_admin: false,
      delete_roles: false,
      modify_roles: false,
      create_roles: false,
      delete_building: false,
      modify_district: false,
      delete_student: false,
      create_student: false,
      modify_staff: false,
      create_building: false,
      modify_building: false,
      modify_student: false,
      roam_buildings: false,
      roam_students: false
    };
    this.roles.push(role);
    this.active = 'new';
  }
}
