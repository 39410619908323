<p>
  Roles allow a school district to enable what a school district is able to do.
</p>

<div class="d-flex">
  <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills" orientation="vertical">
    <ng-container *ngFor="let role of roles">
      <li [ngbNavItem]="role.id">
        <a ngbNavLink>{{role.name}}</a>
        <ng-template ngbNavContent>
          <form ngForm #roleForm="ngForm" (ngSubmit)="submit(roleForm, role)">
            <div class="form-group row">
              <div class="col-sm-12">
                <label for="name">Name</label>
                <input type="text" class="form-control" id="name" name="name" [(ngModel)]="role.name" required>
              </div>
            </div>
            <div class="row">
              <div [class]="sizing">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" name="can_admin" [(ngModel)]="role.can_admin" id="can_admin">
                  <label class="form-check-label mr-2" for="can_admin">Can Administrate</label>
                  <span class="fa fa-info-circle" ngbTooltip="This should be reserved for those have can change district settings or are able to do uploads. Enabling this allows access to the district administration menu.">&nbsp;</span>
                </div>
              </div>
              <div [class]="sizing">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" name="can_impersonate" [(ngModel)]="role.can_impersonate" id="can_impersonate">
                  <label class="form-check-label mr-2" for="can_impersonate">Can Impersonate</label>
                  <span class="fa fa-info-circle" ngbTooltip="This allows a staff member to assume the identity of another staff member. This should be used strictly for troubleshooting.">&nbsp;</span>
                </div>
              </div>
              <div [class]="sizing">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" name="modify_staff" [(ngModel)]="role.modify_staff" id="modify_staff">
                  <label class="form-check-label mr-2" for="modify_staff">Modify Staff</label>
                  <span class="fa fa-info-circle" ngbTooltip="Can this user modify staff members, include granting roles?">&nbsp;</span>
                </div>
              </div>
              <div [class]="sizing">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" name="create_student" [(ngModel)]="role.create_student" id="create_student">
                  <label class="form-check-label mr-2" for="create_student">Create Student</label>
                  <span class="fa fa-info-circle" ngbTooltip="Can this user modify student information?">&nbsp;</span>
                </div>
              </div>
              <div [class]="sizing">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" name="delete_student" [(ngModel)]="role.delete_student" id="delete_student">
                  <label class="form-check-label mr-2" for="delete_student">Delete Student</label>
                  <span class="fa fa-info-circle" ngbTooltip="Can this user delete a student?">&nbsp;</span>
                </div>
              </div>
              <div [class]="sizing">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" name="modify_district" [(ngModel)]="role.modify_district" id="modify_district">
                  <label class="form-check-label mr-2" for="modify_district">Modify District</label>
                  <span class="fa fa-info-circle" ngbTooltip="Can this user modify the district? Such as name and domains, etc..">&nbsp;</span>
                </div>
              </div>
              <div [class]="sizing">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" name="create_building" [(ngModel)]="role.create_building" id="create_building">
                  <label class="form-check-label mr-2" for="create_building">Create a building</label>
                  <span class="fa fa-info-circle" ngbTooltip="Can this user create a building?">&nbsp;</span>
                </div>
              </div>
              <div [class]="sizing">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" name="modify_building" [(ngModel)]="role.modify_building" id="modify_building">
                  <label class="form-check-label mr-2" for="modify_building">Modify a building</label>
                  <span class="fa fa-info-circle" ngbTooltip="Can this user modify a building?">&nbsp;</span>
                </div>
              </div>
              <div [class]="sizing">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" name="modify_building" [(ngModel)]="role.delete_building" id="delete_building">
                  <label class="form-check-label mr-2" for="delete_building">Delete a building</label>
                  <span class="fa fa-info-circle" ngbTooltip="Can this user delete a building?">&nbsp;</span>
                </div>
              </div>
              <div [class]="sizing">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" name="create_roles" [(ngModel)]="role.create_roles" id="create_roles">
                  <label class="form-check-label mr-2" for="create_roles">Create a role</label>
                  <span class="fa fa-info-circle" ngbTooltip="Can this user create a role?">&nbsp;</span>
                </div>
              </div>
              <div [class]="sizing">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" name="modify_roles" [(ngModel)]="role.modify_roles" id="modify_roles">
                  <label class="form-check-label mr-2" for="modify_roles">Modify a role</label>
                  <span class="fa fa-info-circle" ngbTooltip="Can this user modify a role?">&nbsp;</span>
                </div>
              </div>
              <div [class]="sizing">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" name="delete_roles" [(ngModel)]="role.delete_roles" id="delete_roles">
                  <label class="form-check-label mr-2" for="delete_roles">Modify a role</label>
                  <span class="fa fa-info-circle" ngbTooltip="Can this user delete a role?">&nbsp;</span>
                </div>
              </div>

              <div [class]="sizing">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" name="roam_buildings" [(ngModel)]="role.roam_buildings" id="roam_buildings">
                  <label class="form-check-label mr-2" for="roam_buildings">Roam Buildings</label>
                  <span class="fa fa-info-circle" ngbTooltip="This allows a staff member to not be tied to any particular building, and have access to them all. This should be reserved for district administrators who need access to every student.">&nbsp;</span>
                </div>
              </div>

              <div [class]="sizing">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" name="roam_students" [(ngModel)]="role.roam_students" id="roam_students">
                  <label class="form-check-label mr-2" for="roam_students">Roam Students</label>
                  <span class="fa fa-info-circle" ngbTooltip="This allows a staff member to search for students that they are not directly connected to. Unless tied with Roam Buildings, the staff member can only search for students in their buildings.">&nbsp;</span>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-12 text-right">
                <button type="button" class="btn btn-danger mr-2" (click)="delete(role)">Delete</button>
                <button type="button" class="btn btn-secondary mr-2" (click)="cancel(roleForm)">Cancel</button>
                <button type="submit" class="btn btn-primary" [disabled]="roleForm.valid && !roleForm.touched">Save</button>
              </div>
            </div>
          </form>
        </ng-template>
      </li>
    </ng-container>
    <li ngbNavItem="create">
      <a ngbNavLink (click)="addrole(nav)" roles="create_roles">Add role</a>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="ml-2 mt-2 flex-grow-1"></div>
</div>
