import {Component, OnInit} from '@angular/core';
import {TitleService} from '../../services/title.service';
import {AuthService} from '../../services/authService';
import {Router} from '@angular/router';

@Component({
  selector: 'IntroPage',
  templateUrl: 'introPage.component.html',
  styleUrls: ['introPage.component.scss']
})
export class IntroPageComponent {
  constructor(
    private titleService: TitleService) {
    this.titleService.setTitle('Welcome');
  }
  //
  // ngOnInit(): void {
  //   this.authService.isLoggedIn.subscribe(loggedIn => {
  //     if (loggedIn) {
  //       this.router.navigate(['/home'])
  //     }
  //   });
  // }

}
